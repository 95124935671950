var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"edit-makro"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-control"},[_c('label',[_vm._v("Title:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.makro.title),expression:"makro.title"}],attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(_vm.makro.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.makro, "title", $event.target.value)}}})]),_c('div',{staticClass:"border-2 cursor-pointer border-grey-400"},[_c('editor',{attrs:{"apiKey":"8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp","placeholder":"Antwort hier schreiben","initial-value":_vm.makro.content,"init":{
                height: 324,
                menubar: false,
                branding: false,
                toolbar_location: 'bottom',
                resize: false,
                skin: 'naked',
                statusbar: false,
                plugins: [
                'advlist autolink lists link preview anchor',
                'code fullscreen',
                'paste code',
                ],
                toolbar:
                'undo redo formatselect link bold italic backcolor forecolor \
                    alignleft aligncenter alignright alignjustify \
                    bullist numlist outdent indent \
                    fullscreen',
                }},on:{"input":function($event){return _vm.updateValue($event)}}})],1),_c('button',{staticClass:"mt-10 primary-btn",attrs:{"type":"submit"}},[_vm._v("Update makro")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }