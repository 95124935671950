<template>
    <section class="edit-makro">
        <form @submit.prevent="submitForm">  
            <div class="form-control">
                <label>Title:</label>
                <input type="text" autocomplete="off" v-model="makro.title">
            </div>
            <div class="border-2 cursor-pointer border-grey-400">
                <editor
                    apiKey="8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp"
                    placeholder="Antwort hier schreiben"
                    :initial-value="makro.content"
                    @input="updateValue($event)"
                    :init="{
                    height: 324,
                    menubar: false,
                    branding: false,
                    toolbar_location: 'bottom',
                    resize: false,
                    skin: 'naked',
                    statusbar: false,
                    plugins: [
                    'advlist autolink lists link preview anchor',
                    'code fullscreen',
                    'paste code',
                    ],
                    toolbar:
                    'undo redo formatselect link bold italic backcolor forecolor \
                        alignleft aligncenter alignright alignjustify \
                        bullist numlist outdent indent \
                        fullscreen',
                    }"
                >
                </editor>
            </div>
            <button type="submit" class="mt-10 primary-btn">Update makro</button>
        </form>
    </section>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.getMakro(this.$route.params.id);
        },
        methods: {
            ...mapActions(['getMakro', 'updateMakro']),
            updateValue(value) {
                this.makro.content = value;
            },
            submitForm() {
                this.updateMakro({
                    id: this.makro._id,
                    makro: this.makro
                })
            },
        },
        components: {
            editor: Editor
        },
        computed: {
            ...mapGetters(['makro'])
        }
    }
</script>